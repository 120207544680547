<template>
  <div class="ele-body">
    <el-card shadow="never">
      <!-- 搜索表单 -->
      <el-form :model="table.where" class="ele-form-search "
               @keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
        <div class='d-flexspabet'>
          <div>
<!--            <el-button @click="showEdit=true" class="ele-btn-icon addbtn ele-form-actions" size="small">添加管理员</el-button>-->
          </div>
          <div class="d-flex">
            <el-form-item label="审核状态:" label-width="76px" class="mr-20">
              <el-select v-model="table.where.is_audit" class="input163">
                <el-option :value="0"  label="未审核">未审核</el-option>
                <el-option :value="1" label="通过"></el-option>
                <el-option :value="2" label="驳回">驳回</el-option>
              </el-select>
            </el-form-item>
						<el-form-item>
							<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search" class="ele-btn-icon">搜索</el-button>
							<el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
						</el-form-item>
          </div>
        </div>
      </el-form>
      <!-- 数据表格 -->
      <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)" highlight-current-row>
        <template slot-scope="{index}">
          <el-table-column type="selection" width="45" align="center" fixed="left"/>
          <el-table-column type="index" :index="index" label="序号" width="60" align="center" fixed="left" show-overflow-tooltip/>
          <el-table-column prop="admin_name" label="申请人姓名" show-overflow-tooltip min-width='100px' />
          <el-table-column prop="set_area" label="当前运营区域" show-overflow-tooltip min-width='110px' />
          <el-table-column prop="phone" label="申请人手机号" show-overflow-tooltip min-width='110px' />
          <el-table-column prop="area_data" label="区/县名称" show-overflow-tooltip min-width='110px' />
          <el-table-column prop="area_num" label="区/县数量(个)" show-overflow-tooltip min-width='110px' />
          <el-table-column prop="price" label="所需费用(万元)" show-overflow-tooltip min-width='120px' />
          <el-table-column label="申请时间"  show-overflow-tooltip min-width='110px'>
            <template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
          </el-table-column>
          <el-table-column prop="is_audit" label="审核状态"  show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{scope.row.is_audit==1?'已通过':scope.row.is_audit==2?'已驳回':'未审核'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" :resizable="false" fixed="right">
            <template slot-scope="{row}">
              <el-link @click="edit(row)" icon="el-icon-view" type="primary" :underline="false">查看</el-link>
              <el-popconfirm title="确定要删除此消息吗？"  class="ele-action">
                <el-link slot="reference" icon="el-icon-delete" type="danger" :underline="false" @click="remove(row)">删除</el-link>
              </el-popconfirm>
              <el-dropdown @command="command => dropClick(command,row)" v-if="row.is_audit==0">
              	<span class="el-dropdown-link" style="margin-left: 10px;">
              		<el-link type="primary" icon="el-icon-more" :underline="false"></el-link>
              	</span>
              	<el-dropdown-menu slot="dropdown">
              		<el-dropdown-item icon="el-icon-success" command="success" type="primary" >通过</el-dropdown-item>
              		<el-dropdown-item icon="el-icon-error" command="error" type="danger" >拒绝</el-dropdown-item>
              	</el-dropdown-menu>
              </el-dropdown>
              
            </template>
          </el-table-column>
        </template>
      </ele-data-table>
    </el-card>
    <!-- 编辑弹窗 -->
    <el-dialog v-dialogDrag :title="editForm.id?'查看详情':'添加管理员'" :visible.sync="showEdit" width="600px"
               @closed="editForm={}" :destroy-on-close="true" custom-class="ele-dialog-form" :lock-scroll="false">
      <el-card shadow="never">
        <el-form :model="editForm" ref="editForm" :rules="editRules" label-width="200px">
					<el-form-item label="手机号码(登录账号)：">
						{{editForm.phone}}
					</el-form-item>
					<el-form-item label="区/县名称：">
						{{editForm.area_data}}
					</el-form-item>
					<el-form-item label="区/县数量：">
						{{editForm.area_num}} 个
					</el-form-item>
					<el-form-item label="所需费用：">
						{{editForm.price}} 万元
					</el-form-item>
					<el-form-item label="审核状态：">
						{{editForm.is_audit==1?'已通过':editForm.is_audit==2?'已驳回':'未审核'}}
					</el-form-item>
        </el-form>
      </el-card>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "SysUser",
  data() {
    return {
      table: {url: 'adminaudit/index', where: {}},  // 表格配置
      choose: [],  // 表格选中数据
      showEdit: false,  // 是否显示表单弹窗
      editForm: {},  // 表单数据
      editRules: {  // 表单验证规则
        phone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
					{
						pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, 
						message: '请输入正确的手机号', 
						trigger: 'blur',
					}
        ],
        is_audit: [
          {required: true, message: '请选择是否启用', trigger: 'change'}
        ]
      },
      roleList: [],  // 角色列表
      levelList:[], // 职级列表
      positionList:[], // 岗位列表
      provArr:[],
      cityArr:[],
      districtArr:[],
      adminList:[{type:1,name:'总管理员'},{type:2,name:'省代理'},{type:3,name:'市代理'},{type:4,name:'广告商'},{type:5,name:'运营商'}],
      isCheckList:[{type:1,name:'待审核'},{type:2,name:'已审核'},{type:3,name:'已驳回'}]
    }
  },
  mounted() {
    this.queryRoles();  // 查询角色列表
    this.getLevelList(); // 查询职级列表
    this.getPositionList(); // 查询岗位列表
  },
  created(){
    this.$http.get('/common/province_list').then(res=>{
      var data=JSON.parse(res.data)
      this.provArr=data
    })
  },
  methods: {
		dropClick(command, row) {
			if (command === 'success') { // 查看用户信息
				this.admin_status(row.id,1)
			} else if (command === 'error') { //删除
				this.admin_status(row.id,2)
			}
		},
		/* 更改状态 */
		admin_status(id, check_status) {
			if (check_status == 1) {
				this.$confirm('确认通过该审核？', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/adminaudit/admin_audit_success', {id: id}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			}
			if (check_status == 2) {
				this.$confirm('确认驳回该审核？', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/adminaudit/admin_audit_error', {id: id}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			}
		},
    /**
     *选择省
     **/
    handleChangeProv(e){
      /** 获取被选省份的pid**/
      var pid=''
      this.provArr.forEach(function(item){
        if(item.pid==e){
          pid=item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list',{pid:pid}).then(res=>{
        var data=JSON.parse(res.data)
        this.cityArr=data
        /** 选择省份清空市县**/
        this.table.where.cid=''
        this.table.where.aid=''
      })
    },
    /**
     *选择市
     **/
    handleChangeCity(e){
			if(e){
      /** 获取被选市的cid**/
      var cid=''
      this.cityArr.forEach(function(item){
        if(item.cid==e){
          cid=item.cid
        }
      })
      /** 根据被选市的cid获取市下面的县**/
      this.$http.post('/common/area_list',{cid:cid}).then(res=>{
        var data=JSON.parse(res.data)
        this.districtArr=data
        /** 选择省份清空县**/
        this.table.where.aid=''

      })
			}else{
				this.table.where.pid = ''
				this.table.where.aid = ''
			}
    },
    /* 显示编辑 */
    edit(row) {
      // this.editForm = Object.assign({}, row, {
      //   role_ids: row.roles.map(d => d.id)
      // });
		this.editForm = row;
		this.showEdit = true;
    },
    /* 保存编辑 */
    save() {
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          const loading = this.$loading({lock: true});
          this.$http.post('/admin/edit', this.editForm).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.showEdit = false;
              this.$message({type: 'success', message: res.data.msg});
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },
    /* 删除 */
    remove(row) {
      if (!row) {  // 批量删除
        if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
        let ids = this.choose.map(d => d.id);
        this.$confirm('确定要删除审核记录?', '提示', {type: 'warning'}).then(() => {
          const loading = this.$loading({lock: true});
          this.$http.post('/adminaudit/delete', {id: ids}).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.$message({type: 'success', message: res.data.msg});
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        }).catch(() => 0);
      } else {  // 单个删除
        this.$confirm('确定要删除审核记录?', '提示', {type: 'warning'}).then(() => {
          const loading = this.$loading({lock: true});
          this.$http.post('/adminaudit/delete', {id: row.id}).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.$message({type: 'success', message: res.data.msg});
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        })
      }
    },
    /**
     * 重置密码
     */
    resetPwd(row){
      const loading = this.$loading({lock: true});
      this.$http.post('/admin/resetPwd', {id:row.id}).then(res => {
        loading.close();
        if (res.data.code === 0) {
          this.$message({type: 'success', message: res.data.msg});
          this.$refs.table.reload();
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        loading.close();
        this.$message.error(e.message);
      });
    },
    /* 更改状态 */
    editStatus(row) {
      const loading = this.$loading({lock: true});
      let params = Object.assign({}, row);
      this.$http.post('/admin/status', params).then(res => {
        loading.close();
        if (res.data.code === 0) {
          this.$message({type: 'success', message: res.data.msg});
        } else {
          row.status = !row.status ? 2 : 1;
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        loading.close();
        this.$message.error(e.message);
      });
    },
    /* 查询角色列表 */
    queryRoles() {
      this.$http.get('/role/getRoleList').then(res => {
        if (res.data.code === 0) {
          this.roleList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        this.$message.error(e.message);
      });
    },
    /**
     * 获取职级列表
     */
    getLevelList() {
      this.$http.get('/level/getLevelList').then(res => {
        if (res.data.code === 0) {
          this.levelList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        this.$message.error(e.message);
      });
    },
    /**
     * 获取岗位列表
     */
    getPositionList() {
      this.$http.get('/position/getPositionList').then(res => {
        if (res.data.code === 0) {
          this.positionList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        this.$message.error(e.message);
      });
    }
  }
}
</script>

<style scoped>
.ele-block >>> .el-upload, .ele-block >>> .el-upload-dragger {
  width: 100%;
}
/* 用户资料卡片 */
.user-info-card {
  padding-top: 8px;
  text-align: center;
}

.user-info-card .user-info-avatar-group {
  position: relative;
  cursor: pointer;
  margin: 0 auto;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  overflow: hidden;
}

.user-info-card .user-info-avatar {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  object-fit: cover;
}

.user-info-card .user-info-avatar-group > i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #FFF;
  font-size: 30px;
  display: none;
  z-index: 2;
}

.user-info-card .user-info-avatar-group:hover > i {
  display: block;
}

.user-info-card .user-info-avatar-group:hover:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .3);
}
</style>
